.Thanks {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    padding: 0px 5%;
    background-color: #2a1768;

    .MainBanner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-direction: column;
        // flex-direction: row-reverse;
        background-color: #eceef6;
        overflow: hidden;
        height: 60vh;
        border-radius: 10px;
        width: 60vh;
        .MainBannerLeft {
            display: flex;
            align-items: center;
            justify-content: start;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 5%;
            img {
                width: 100px;
                background-color: white;
                padding: 10px;
                border-radius: 50%;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            }
            h1 {
                text-align: center;
                font-size: 30px;
                font-weight: 700;
                color: #2a1768;
                margin-top: 20px;
            }
            h2 {
                text-align: center;
                font-size: 20px;
                font-weight: 400;
                color: #2a1768;
                margin: 15px 0px;
            }
            h3 {
                text-align: center;
                font-size: 22px;
                font-weight: 600;
                color: #ffffff;
                margin: 15px 0px;
                background-color: #da291c;
                padding: 10px 20px;
                border-radius: 20px;
                box-shadow: #959da533 0px 8px 24px;
            }
            h4 {
                border-left: 4px solid #0c0034;
                padding: 15px;
                background-color: white;
                border-radius: 0px 5px 5px 0px;
                font-size: 17px;
                font-weight: 400;
                color: #0c0034;
                margin: 15px 0px;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            }
            .ahomepagethamnk {
                border-left: 4px solid #0c0034;
                padding: 15px;
                background-color: white;
                border-radius: 0px 5px 5px 0px;
                font-size: 17px;
                font-weight: 400;
                color: #0c0034;
                margin: 15px 0px;
                width: 100%;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                text-decoration: underline;
                cursor: pointer;
                z-index: 5;
            }
        }
        .MainBannerRight {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 50%;
            height: 100%;
            position: relative;
            img {
                position: absolute;
                bottom: -50px;
                left: 100px;
                margin-top: 100px;
                width: 320px;
                z-index: 6;
                border-radius: 0px;
            }
            .MainBannerRightBubble1 {
                // display: none;
                position: absolute;
                right: 290px;
                bottom: -200px;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: #ffffff;
                opacity: 0.8;
                z-index: 3;
                box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
            }
            .MainBannerRightBubble2 {
                // display: none;
                position: absolute;
                right: 50px;
                bottom: -150px;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                opacity: 0.5;
                background-color: #ffffff;
                z-index: 3;
                box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
            }
        }
    }
}
@media (max-width: 800px) {
    .Thanks {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        width: 100vw;
        height: 100vh;
        padding: 0px 5%;
        padding-top: 15px;
        background-color: #2a1768;

        .MainBanner {
            width: 97%;
            height: auto;
            .MainBannerLeft {
                width: 100%;
                img {
                    width: 50px;
                    background-color: white;
                    padding: 10px;
                    border-radius: 50%;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                }
                h1 {
                    font-size: 18px;
                    margin-top: 10px;
                }
                h2 {
                    text-align: center;
                    font-size: 14px;
                    font-weight: 400;
                    color: #2a1768;
                    margin: 15px 0px;
                }
                h3 {
                    font-size: 17px;
                    margin: 5px 0px;
                }
                h4 {
                    padding: 15px;
                    font-size: 14px;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                    z-index: 5;
                }
                .ahomepagethamnk {
                    font-size: 14px;
                    z-index: 5;
                }
            }
            .MainBannerRight {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                width: 50%;
                height: 100%;
                position: relative;
                img {
                    position: absolute;
                    bottom: -50px;
                    left: 100px;
                    margin-top: 100px;
                    width: 320px;
                    z-index: 2;
                    border-radius: 0px;
                }
                .MainBannerRightBubble1 {
                    // display: none;
                    position: absolute;
                    right: 290px;
                    bottom: -200px;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background-color: #ffffff;
                    opacity: 0.8;
                    z-index: 3;
                    box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
                }
                .MainBannerRightBubble2 {
                    // display: none;
                    position: absolute;
                    right: 50px;
                    bottom: -150px;
                    width: 90px;
                    height: 90px;
                    border-radius: 50%;
                    opacity: 0.5;
                    background-color: #ffffff;
                    z-index: 3;
                    box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
                }
            }
        }
    }
}
@media screen and (min-width: 1700px) {
    .Thanks {
        padding: 0px 20%;
    }
}
