.LocationReviews {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    // padding: 50px 5% 0px 5%;
    .MainBanner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-width: 100vw;
        background-color: #2a1768;
        padding: 60px 5% 60px 5%;
        overflow: hidden;
        .MainBannerLeft {
            width: 100%;
            padding-right: 20px;
            h1 {
                font-size: 40px;
                font-weight: 600;
                color: white;
            }
            h2 {
                font-size: 17px;
                font-weight: 200;
                color: white;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            span {
                display: inline-flex;
                flex-direction: column;
                margin-top: 0px;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 19px;
                    font-weight: 500;
                    cursor: pointer;
                    background-color: white;
                    color: rgb(0, 0, 0);
                    cursor: pointer;
                    padding: 15px 15px;
                    padding-left: 20px;
                    margin-right: 20px;
                    margin-top: 20px;

                    img {
                        // background-color: white;
                        width: 20px;
                        margin-left: 20px;
                        filter: invert(0);
                    }
                }

                a:last-child {
                    background-color: #c3c3c300;
                    color: #ffffff;
                    background-color: #da291c;
                    border: 2px solid white;
                    img {
                        filter: invert(1);
                    }
                }
                a:hover,
                a:last-child:hover {
                    background-color: #e4e4e4;
                    color: black;
                    img {
                        filter: invert(0);
                    }
                }
            }
            .GoogleButt {
                display: flex;
                flex-direction: column;
                margin-top: 30px;

                img {
                    width: 150px;
                    height: auto;
                }
                h6 {
                    font-size: 17px;
                    font-weight: 200;
                    color: white;
                    margin-top: 15px;
                }
                a {
                    color: white;
                    text-decoration: underline;
                    margin-top: 10px;
                }
            }
        }
        .BookAnAppointmentMainComponentEmergery {
            display: flex;
            align-items: flex-start;
            justify-content: start;
            flex-direction: column;
            background-color: #2a1768;
            padding: 20px 0px;
            overflow: auto;
            width: 100%;

            .BookAnAppointmentMainComponentEmergeryBox {
                display: flex;
                align-items: center;
                justify-content: start;
                background-color: #1382d72b;
                padding: 12px 20px;
                border-radius: 8px;
                margin-top: 20px;
                overflow: auto;

                a {
                    color: #ffffff;
                    font-size: 20px;
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
            .BookAnAppointmentMainComponentRates {
                margin-top: 20px;
                border-radius: 10px;
                margin-top: 10px;
                padding: 20px;
                background-color: #1382d72b;
                height: auto;
                overflow: auto;
                width: 100%;
                .BookAnAppointmentMainComponentRatesImages {
                    display: flex;
                    overflow: auto;
                    img {
                        width: auto;
                        height: 80px;
                        object-fit: cover;
                        margin-right: 20px;
                        background-color: rgb(255, 255, 255);
                        padding: 7px;
                        border-radius: 5px;
                    }
                }
                h1 {
                    color: #ffffff;
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 20px;
                }
                h2 {
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 10px;
                }
                h3 {
                    color: #ffffff;
                    font-size: 17px;
                    font-weight: 400;
                    margin-top: 20px;
                    a {
                        color: white;
                        text-decoration: underline;
                        margin-left: 5px;
                        font-size: 12px;
                        padding-top: 20px;
                    }
                }
            }
        }
    }
    .LocationReviewsListMap {
        display: flex;
        background-color: #e9e9e99c;
        padding: 50px 5% 50px 5%;
        position: relative;

        .LocationReviewsLsit {
            width: 100%;

            .LocationReviewsBox {
                display: flex;
                flex-direction: column;
                border-top: 3px solid #dcdcdc80;
                background-color: white;
                margin-bottom: 25px;
                border: 1px solid #cacaca;
                border-radius: 8px;
                .LocationReviewsBoxHeader {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: #2a1768;
                    padding: 20px;
                    .LocationReviewsBoxHeaderBox {
                        h1 {
                            font-size: 23px;
                            font-weight: 500;
                            color: rgb(255, 255, 255);
                        }
                        h2 {
                            font-size: 19px;
                            font-weight: 200;
                            color: rgb(255, 255, 255);
                            margin-top: 5px;
                        }
                    }
                }
                .LocationReviewsBoxLeft {
                    width: 100%;
                    padding-right: 20px;
                    padding: 20px;

                    h3 {
                        width: 100%;
                        font-size: 16px;
                        font-weight: 300;
                        color: #000000;
                        text-align: start;
                        margin-top: 10px;
                    }
                    h2 {
                        width: 100%;
                        font-size: 30px;
                        font-weight: 600;
                        color: #2a1768;
                        text-align: start;
                        margin-top: 15px;
                    }
                    h4 {
                        width: 100%;
                        font-size: 18px;
                        font-weight: 400;
                        color: #000000;
                        text-align: start;
                        margin-top: 10px;
                    }
                    h5 {
                        border-radius: 5px;
                        width: 100%;
                        font-size: 15px;
                        font-weight: 600;
                        text-align: start;
                        margin-top: 10px;
                        color: #2a1768;
                        a {
                            color: #2a1768;
                            text-decoration: underline;
                        }
                    }
                    .LocationReviewsBoxLeftBox {
                        margin-top: 20px;
                        width: 100%;
                        border: 1px solid #dcdcdc80;
                        border-radius: 5px;
                        padding: 20px;
                        .LocationReviewsBoxLeftBoxBox {
                            border-bottom: 1px solid #dcdcdc80;
                            padding-bottom: 10px;
                            h5 {
                                font-size: 15px;
                                font-weight: 600;
                                text-align: start;
                                margin-top: 10px;
                                color: #000000;
                            }
                            h6 {
                                font-size: 15px;
                                font-weight: 400;
                                text-align: start;
                                margin-top: 10px;
                                color: #000000;
                                a {
                                    color: black;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
            .LocationAllBox {
                display: flex;
                // flex-direction: column;
                border-top: 3px solid #dcdcdc80;
                background-color: white;
                padding: 20px 10px;
                margin-bottom: 25px;
                border: 1px solid #cacaca;
                border-radius: 8px;
                padding: 20px;
                .LocationAllBoxLeft {
                    width: 60%;
                    padding-right: 20px;
                    h2 {
                        width: 100%;
                        font-size: 35px;
                        font-weight: 600;
                        color: #2a1768;
                        text-align: start;
                    }
                    h3 {
                        width: 100%;
                        font-size: 16px;
                        font-weight: 300;
                        color: #000000;
                        text-align: start;
                        margin-top: 10px;
                    }
                    h4 {
                        width: 100%;
                        font-size: 18px;
                        font-weight: 400;
                        color: #000000;
                        text-align: start;
                        margin-top: 20px;
                    }
                    h5 {
                        border-radius: 5px;
                        width: 100%;
                        font-size: 15px;
                        font-weight: 600;
                        text-align: start;
                        margin-top: 10px;
                        color: #2a1768;
                        a {
                            color: #2a1768;
                            text-decoration: underline;
                        }
                    }
                    span {
                        display: flex;
                        margin-top: 20px;
                        width: 100%;
                        a {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 10px 10px;
                            color: white;
                            background-color: #2a1768;
                            border-radius: 5px;
                            border: 2px solid #2a1768;
                            margin-right: 10px;
                            font-size: 15px;
                            img {
                                width: 20px;
                                margin-right: 10px;
                                filter: invert(1);
                                // filter: invert(13%) sepia(62%) saturate(4816%) hue-rotate(219deg) brightness(107%) contrast(102%);
                            }
                        }
                        a:hover {
                            color: #2a1768;
                            background-color: white;
                            border: 2px solid #2a1768;
                            img {
                                filter: invert(13%) sepia(62%) saturate(4816%) hue-rotate(219deg) brightness(107%)
                                    contrast(102%);
                            }
                        }
                    }
                }
                .LocationAllBoxRight {
                    display: none;
                    width: 40%;
                    .Mapsss {
                        width: 100%;
                        height: 100%;
                        background-color: #dcdcdc00;
                        border: 2px solid #dcdcdc;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}

@media (max-width: 850px) {
    .LocationReviews {
        .MainBanner {
            flex-direction: column;
            padding: 20px 5% 20px 5%;
            height: auto;
            min-height: auto;
            .MainBannerLeft {
                width: 100%;
                height: auto;
                margin-top: 30px;
                padding-right: 0px;
                h1 {
                    font-size: 30px;
                }
                h2 {
                    font-size: 18px;
                    margin-bottom: 20px;
                }
                h3 {
                    margin-top: 7px;
                }
                .GoogleButt {
                    img {
                        width: 200px;
                    }
                }
            }
            .BookAnAppointmentMainComponentEmergery {
                width: 100%;
                .BookAnAppointmentMainComponentEmergeryBox {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    background-color: #1382d72b;
                    padding: 12px 20px;
                    border-radius: 8px;
                    margin-top: 20px;

                    a {
                        color: #ffffff;
                        font-size: 20px;
                        font-weight: 600;
                        text-decoration: underline;
                    }
                }
                .BookAnAppointmentMainComponentRates {
                    margin-top: 20px;
                    border-radius: 10px;
                    margin-top: 10px;
                    padding: 20px;
                    background-color: #1382d72b;
                    width: 100%;
                    padding-top: 0px;

                    .BookAnAppointmentMainComponentRatesImages {
                        display: flex;
                        width: 100%;
                        // flex-direction: column;
                        overflow: auto;
                        padding-bottom: 10px;
                        img {
                            width: auto;
                            height: 60px;
                            object-fit: cover;
                            margin-right: 20px;
                            margin-top: 20px;
                        }
                    }
                    h1 {
                        color: #ffffff;
                        font-size: 20px;
                        font-weight: 600;
                        margin-top: 20px;
                    }
                    h2 {
                        font-size: 20px;
                        font-weight: 600;
                        margin-top: 10px;
                    }
                    h3 {
                        color: #ffffff;
                        font-size: 13px;
                        font-weight: 400;
                        margin-top: 20px;
                    }
                }
            }
        }
        .LocationReviewsListMap {
            flex-direction: column-reverse;
            .LocationReviewsLsit {
                width: 100%;
                padding-right: 0px;
                .LocationReviewsBox {
                    flex-direction: column;
                    .LocationReviewsBoxHeader {
                        .LocationReviewsBoxHeaderBox {
                            h1 {
                                font-size: 16px;
                            }
                            h2 {
                                font-size: 14px;
                            }
                        }
                    }
                    .LocationReviewsBoxLeft {
                        width: 100%;
                        h2 {
                            font-size: 20px;
                        }
                        h3 {
                            font-size: 12px;
                        }
                        h4 {
                            font-size: 14px;
                            margin-top: 15px;
                        }
                        .LocationReviewsBoxLeftBox {
                            padding: 10px;
                            .LocationReviewsBoxLeftBoxBox {
                                h6 {
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                }
                .LocationAllBox {
                    flex-direction: column;
                    .LocationAllBoxLeft {
                        width: 100%;
                        padding-right: 0px;
                        h2 {
                            font-size: 28px;
                        }
                        h3 {
                            font-size: 12px;
                        }
                        h4 {
                            font-size: 15px;
                            margin-top: 15px;
                        }
                        span {
                            flex-direction: column;
                            width: 100%;
                            margin-top: 10px;

                            a {
                                margin-top: 10px;
                                margin-right: 0px;
                            }
                        }
                    }
                    .LocationAllBoxRight {
                        // display: none;
                        // display: none;
                        // display: none;
                        // display: none;
                        // display: none;
                        width: 100%;
                        height: 200px;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1700px) {
    .LocationReviews {
        .MainBanner,
        .LocationReviewsTitle,
        .LocationReviewsListMap {
            padding-right: 13%;
            padding-left: 13%;
        }
    }
}
