.Menu {
  display: flex;
  width: 100vw;
  height: calc(100vh - 60px);
  position: fixed;
  right: 0;
  top: 60px;
  z-index: 9;
  animation: example 0.3s ease-out;
  background-color: #0000009e;

  .MenuBoxShadow {
    // background-color: #0000009e;
    backdrop-filter: blur(2px);
    width: 70vw;
    height: 100vh;
  }
  .MenuList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 500px;
    height: 100%;
    background-color: #2a1768;

    .MenuListTop {
      padding: 10px 20px 60px 20px;
      position: relative;
      width: 100%;
      .MenuMain,
      .MenuMain2 {
        padding-top: 20px;
        width: 100%;
        .MenuMainBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-bottom: 10px;
          padding-top: 8px;
          cursor: pointer;

          h1,
          a {
            font-size: 20px;
            font-weight: 500;
            color: white;
            text-decoration: unset;
          }

          img {
            width: 30px;
            filter: invert(1);
          }
        }
        .MenuMainBox:hover {
          h1,
          a {
            color: rgb(219, 219, 219);
          }
        }
      }
      .MenuMain2 {
        // display: none;
        border-top: 1px solid #d4d4d4;
        margin-top: 20px;
      }
      .MenuFooter {
        display: flex;
        display: none;

        justify-content: center;
        padding-top: 25px;
        margin-top: 25px;
        border-top: 1px solid #d4d4d4;

        h5 {
          color: #ffffff;
          font-weight: 300;
          font-size: 12px;
        }
      }
      // MenuMakers
      .MenuMakers {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 100vh;
        // padding: 10px 40px 40px 40px;
        background-color: #2a1768;
        animation: tegories 0.3s ease-out;
        overflow: scroll;

        .MenuMakersHeader {
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(100% + 0px);
          margin-bottom: 20px;
          padding: 15px 0px;
          border-bottom: 1px solid #ffffff;
          position: relative;
          h1 {
            font-size: 20px;
            font-weight: 300;
            color: #ffffff;
            cursor: pointer;
          }
          h1:hover {
            color: rgb(10, 10, 10);
          }
          img {
            position: absolute;
            cursor: pointer;
            left: 20px;
            transform: rotate(180deg);
            width: 30px;
            filter: invert(1);
          }
        }
        .MenuMakersList {
          width: 100%;
          padding: 0px 40px 100px 40px;
          .MenuMakersListBox {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 20px;
            a {
              font-size: 20px;
              font-weight: 400;
              color: #ffffff;
              cursor: pointer;
              text-decoration: unset;
              margin-top: 10px;
              text-decoration: underline;
            }
            a:hover {
              color: rgb(219, 219, 219);
            }
          }
          .MenuMakersListBox:nth-child(2) {
            img {
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
            }
          }
        }
      }
      .MenuMainBoxImg {
        display: none;
        align-content: center;
        justify-content: center;
        width: 100%;
        background-color: white;
        border-radius: 8px;
        border: 2px solid #da291c;
        img {
          // margin-top: 50px;
          width: 150px;
          height: 100px;
        }
      }
    }
    .MenuListFooter {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: calc(100% - 40px);
      height: auto;
      border-top: 1px solid #d4d4d4;
      padding: 20px 0px 40px 0px;
      margin: 0px 20px;

      p {
        color: white;
        text-align: center;
        margin-top: 20px;
      }
      a {
        color: white;
        text-decoration: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 15px 20px;
        font-size: 20px;
        font-weight: 600;
        background-color: #da291c;
        border-radius: 3px;
        margin-bottom: 20px;
      }
    }
  }
}
@keyframes example {
  0% {
    right: -100vw;
    // transform: translateY(-100%);
  }
  100% {
    right: 0vw;

    // transform: translateY(0);
  }
}
@keyframes categories {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@media (max-width: 850px) {
  .Menu {
    width: 100%;
    top: 50px;
    height: 100%;
    .MenuBoxShadow {
      display: none;
    }
    .MenuList {
      width: 100vw;
      height: calc(100% - 50px);
      .MenuListTop {
        padding: 10px 5% 40px 5%;
      }
    }
  }
}

@media screen and (min-width: 1700px) {
  .Menu {
    width: 100vw;
    .MenuBoxShadow {
      background-color: #00000000;
      width: 0%;
    }
    .MenuList {
      width: 100%;
      padding: 0px 20%;

      .MenuListTop {
        width: 100%;
      }
    }
  }
}
